<template>
  <section class="register">
    <div class="grid-x">
      <div class="cell auto">
        <form @submit.prevent="register">
          <h4 class="title">S'inscrire</h4>
            <div class="row">
              <app-input
                label="Email"
                v-model="email"
                placeholder="Email..." type="email"
                :hasSucceed="isMailFormInvalid ? 'error' : null"
                :msg="isMailFormInvalid ? errorMessage : null"
                required />
            </div>
            <div class="row">
              <app-input
                label="Prénom"
                v-model="firstname"
                placeholder="Prénom..."
                type="text"
                required
                :maxlength="128" />
            </div>
            <div class="row">
              <app-input
                label="Nom"
                v-model="lastname"
                placeholder="Nom..."
                type="text"
                required
                :maxlength="128" />
            </div>
            <div class="row">
              <app-input
                label="Numéro de téléphone"
                v-model="phone"
                placeholder="Numéro..."
                type="phone"
                :hasSucceed="isPhoneFormInvalid ? 'error' : null"
                :msg="isPhoneFormInvalid ? errorMessage : null"
                required
                :maxlength="10"
                :minlength="10" />
            </div>
            <div>
              <app-search-city
                placeholder="Commune ou code postal"
                @select="setSelectedPostalCode"
                @clear="resetSelectedPostalCode"
                @submit-city="submitCity"
                :selectedPostalCode="selectedPostalCode"
                label="Lieu de construction"
                :required="true"
                :noSubmitBtn="true"
              />
            </div>
            <div class="row affiliate-city">
              <div v-if="agency && !agencyError">
                <p class="affiliate-agency">Votre compte sera affilié à : {{ agency.name }}</p>
              </div>
              <div v-else-if="!agency && agencyError">
                <p class="agency-error">Il n'y a aucune agence Alysia dans cette commune, essayez une autre commune !</p>
              </div>
            </div>
            <div class="row">
              <app-input
                label="Mot de passe"
                v-model="password"
                placeholder="Mot de passe..."
                type="password"
                required />
            </div>
            <div class="row">
              <app-button type="submit" :disabled="areFieldsEmpty">S'inscrire</app-button>
            </div>
            <router-link :to="{ name: 'login' }">Déjà un compte ? Se connecter</router-link>
          </form>
      </div>
      <div class="cell auto presentation">
        <div class="blur"></div>
        <div class="content">
          <logo-bird class="logo"/>
          <h6>Le plus court chemin entre vous et votre maison</h6>
          <router-link class="back-link" :to="{ name: 'home' }">
            <app-button theme="secondary">Retour au site</app-button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import agencyApi from '@/services/api/agency';
import logoBird from '@/assets/logos/bird.svg?inline';

const { VUE_APP_BRAND_ID } = process.env;

export default {
  components: {
    'logo-bird': logoBird,
  },
  data() {
    return {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      agency: null,
      password: '',
      errorMessage: '',
      agencyError: false,
      isMailFormInvalid: false,
      isPhoneFormInvalid: false,
      postalCodeInseeNumber: null,
      selectedPostalCode: null,
      isOpen: false,
    };
  },
  computed: {
    areFieldsEmpty() {
      return !(this.email && this.firstname && this.phone && this.agency && this.password);
    },
  },
  methods: {
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.postalCodeInseeNumber = null;
    },
    setSelectedPostalCode(value) {
      this.selectedPostalCode = value.label;
      this.postalCodeInseeNumber = value.name;
    },
    submitCity() {
      if (this.postalCodeInseeNumber && this.postalCodeInseeNumber.length > 4) {
        this.getAgencies();
      }
    },
    async getAgencies() {
      try {
        const agency = await agencyApi.getByPostalCode(this.postalCodeInseeNumber);
        if (agency) {
          this.agency = agency;
          this.agencyError = false;
        } else {
          this.agencyError = true;
        }
      } catch (e) {
        this.errorMessage = 'Une erreur est survenue';
      }
    },
    async register() {
      this.errorMessage = '';
      try {
        const brandId = VUE_APP_BRAND_ID;
        await authApi.register(this.email, this.password, this.firstname, this.lastname, this.phone, brandId, this.agency.agencyId);
        this.isMailFormInvalid = false;
        this.isPhoneFormInvalid = false;
        // On log automatiquement après l'inscription
        await authApi.login(this.email, this.password);

        this.$analyticsModal.show({
          title: 'Merci !',
          text: 'Votre compte a bien été créé',
          hasConfirm: true,
          hasCancel: true,
          confirmText: 'Voir mon compte',
          cancelText: 'Retour au site',
          queryName: 'remerciement-inscription',
          onConfirm: async () => {
            await this.$router.push({ name: 'account', params: { tab: 'mes-projets' } }).catch(() => true);
          },
        });
      } catch (e) {
        if (e.response.status === 400 && e.response.data.message === 'Email already used') {
          this.isMailFormInvalid = true;
          this.errorMessage = 'Cette adresse est déjà utilisée sur un compte';
        } if (e.response.status === 400 && e.response.data.message[0] === 'Not a valid phone number') {
          this.isPhoneFormInvalid = true;
          this.errorMessage = 'Le format du numéro de téléphone n\'est pas correct';
        } else {
          this.isMailFormInvalid = true;
          this.isPhoneFormInvalid = true;
          this.errorMessage = 'Une erreur est survenue';
        }
      }
    },
  },
};

</script>

<style lang="sass" scoped>
  .register
    margin-top: 70px
    > .grid-x
      min-height: 80vh
    .cell
      padding: 40px
    .title
      color: black
      font-size: 20px
      font-weight: 500
    .row
      @include row
      width: 100%
    .affiliate-city
      margin-top: 4px
    label > span
      @include label
    .agency-error
      @include infos
      color: $error
      margin-top: 6px
    .affiliate-agency
      @include infos
      margin-top: 6px
    form
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
      max-width: 400px
      margin: auto
      h4
        color: $body-color
      a
        &.forgotten-password
          align-self: flex-end
          margin-top: 19px
          margin-bottom: 30px
        &.sign-in
          margin-top: 18px
      button
        width: fit-content
        padding-left: 20px
        padding-rigth: 20px
    .presentation
      background-image: url(../../assets/img/pages/background-house.jpeg)
      background-size: cover
      background-position: center
      position: relative
      @media (max-width: 1080px)
        display: none
      .blur
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 1
        @include black-filter
      .content
        height: 100%
        z-index: 2
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .logo
          margin-bottom: 20px
      h6
        color: $white
      .back-link
        margin-top: 30px
        margin-bottom: 50px
        text-decoration: none
</style>
